@import "../../../scss/variables";

._catalogue-product-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 0 0 0;

    &__center {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1000px;
        align-items: flex-start;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media (min-width: $dim-breakpoint-lg) {
            max-width: $dim-breakpoint-lg;
        }
    }

    &__categories {
        padding: 1rem;
        width: 100%;

        @media (min-width: $dim-breakpoint-sm) {
            flex: 0 0 30rem;
        }
    }

    &__overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 4rem 0 0 0;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba($color-background, 0.8);
        font-size: 2rem;
        text-align: center;

        &-title {
            font-size: 2rem;
            text-align: center;
            font-weight: bold;
            color: $color-grey-2;
        }
    }

    &__title {
        width: 100%;
        padding: 1rem;
        font-size: 3rem;
        font-weight: bold;
    }
}

._catalogue-category-tree {
    &__main-list {
        display: flex;
        flex-direction: column;
        background-color: $color-elem-background;
        border-radius: 0.6rem;
        overflow: hidden;
        box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
    }

    &__main {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            border-bottom: 2px solid $color-grey-4;
        }
    }

    &__main-row {
        display: flex;
        padding: 1.4rem;
        justify-content: space-between;
        font-weight: 500;
        cursor: pointer;

        &-label {
            font-size: 1.6rem;
            font-weight: 500;
        }

        &-icon {
            @include ff-icon();
            font-size: 2rem;
            color: $color-grey-1;
        }

        &.selected {
            background-color: $color-primary;
            color: $color-primary-c;

            ._catalogue-category-tree__main-row {
                &-icon {
                    color: $color-primary-c;
                }
            }
        }
    }

    &__secondary-list {
        background-color: $color-grey-5;
    }

    &__secondary-row {
        display: flex;
        justify-content: space-between;
        padding: 1.4rem;
        border-top: 1px solid $color-grey-4;
        font-weight: 500;
        border-left: 5px solid $color-grey-3;
        cursor: pointer;

        &-label {
            font-size: 1.4rem;
        }

        &-icon {
            @include ff-icon;
            font-size: 2rem;
            color: $color-grey-1;
        }

        &.selected {
            background-color: $color-primary;
            color: $color-primary-c;

            ._catalogue-category-tree__secondary-row {
                &-icon {
                    color: $color-primary-c;
                }
            }
        }
    }
}

._catalogue-category-products {
    &__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 0 30rem;
        position: relative;
        align-content: flex-start;
        padding-bottom: 5rem;
    }

    &__product {
        flex: 0 0 100%;
        padding: 1rem;
        display: flex;
        align-items: stretch;

        @media (min-width: $dim-breakpoint-xs) {
            flex: 0 0 50%;
            overflow: hidden;
        }

        @media (min-width: $dim-breakpoint-lg) {
            flex: 0 0 33.33%;
        }

        &-content {
            background-color: $color-elem-background;
            box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
            border-radius: 0.5rem;
            overflow: hidden;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &-photo {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid $color-grey-4;
            cursor: pointer;

            &-img {
                width: 100%;
            }
        }

        &-no-photo {
            height: 14rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon;
            font-size: 4rem;
            color: $color-grey-1;
        }

        &-title {
            padding: 1rem;
            font-size: 2rem;
            color: $color-primary;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        &-type {
            padding: 0 1rem 1rem;
            font-size: 1.4rem;
        }

        &-ean {
            padding: 0 1rem 1rem;
            font-size: 2rem;
            font-weight: 500;
            color: $color-grey-2;
        }

        &-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 1rem;

            flex: 1 0 4rem;

            .button {
                margin-left: 1rem;
                display: flex;
                align-items: center;
            }
        }
    }
}
