@import "../../scss/variables";

._auth-login {
    width: 100%;
    display: flex;
    padding: 4rem 0;
    justify-content: center;

    &__content {
        width: 300px;
        background-color: $color-elem-background;
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;

        input {
            width: 100%;
            margin: 0 0 1rem 0;
            padding: 1rem;
            border: 2px solid $color-primary;
            border-radius: 0.3rem;
            background-color: $color-primary-c;
        }
    }
}
