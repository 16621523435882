.button {
    padding: 0.5rem 1rem;
    border: 2px solid $color-primary;
    border-radius: 0.3rem;
    text-transform: uppercase;
    font-weight: 700;
    background-color: $color-primary-c;
    transition: all 0.2s ease-out;
    color: $color-primary;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    @include ff-ge;
    box-sizing: border-box;

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
    }

    &.fill {
        background-color: $color-primary-light;
        color: $color-primary-c;

        &:hover {
            background-color: $color-primary-dark;
            border-color: $color-primary-dark;
        }
    }
}
