@import "../scss/variables";

._nav {
    background-color: $color-elem-background;
    height: $dim-nav-height;
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;

    @media print {
        display: none;
    }

    &__center {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: $dim-breakpoint-sm) {
            order: 1;
        }

        @media screen and (min-width: $dim-breakpoint-lg) {
            max-width: $dim-breakpoint-lg;
        }
    }

    &__logo {
        width: $dim-nav-height;
        height: $dim-nav-height;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 2rem;
        cursor: pointer;

        &-img {
            width: 5rem;
        }
    }

    &__right {
        flex: 1 0 10rem;
        display: flex;
        justify-content: flex-end;

        @media screen and (min-width: $dim-breakpoint-sm) {
            order: 3;
            flex: 0 0 auto;
        }

        &-button {
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon();
            font-size: 2.5rem;
            color: $color-primary;

            @media screen and (min-width: $dim-breakpoint-sm) {
                display: none;
            }
        }
    }

    &__links {
        display: none;
        justify-content: flex-start;
        flex-direction: column;
        flex: 1 0 100%;
        background-color: $color-elem-background;

        &.visible-sm {
            display: flex;
            border-bottom: 1px solid $color-grey-1;

            @media (min-width: $dim-breakpoint-sm) {
                border-bottom: none;
            }
        }

        @media screen and (min-width: $dim-breakpoint-sm) {
            order: 2;
            display: flex;
            flex: 1 0 50%;
            flex-direction: row;
        }

        &-left {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $dim-breakpoint-sm) {
                flex-direction: row;
                flex: 1 0 10rem;
            }
        }
        &-right {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $dim-breakpoint-sm) {
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }
    &__link {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
        color: $color-primary;
        padding: 1rem;
        border: none;
        @include ff-text();
        background-color: transparent;
        cursor: pointer;

        &.lang {
            color: $color-grey-1;
        }

        &.selected {
            font-weight: bold;
            color: $color-primary;
        }

        &-langauge-switch {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
        }

        &-search {
            display: flex;
            justify-content: center;
            align-items: center;
            @include ff-icon;
            font-size: 2.6rem;
            color: $color-primary;
            cursor: pointer;
        }

        &-order {
            &-label {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-size: 1.6rem;
                text-transform: uppercase;
                font-weight: 500;
                text-decoration: none;
                color: $color-primary;
                padding: 1rem;
                border: none;
                @include ff-text();
                background-color: transparent;
                cursor: pointer;

                @media (min-width: $dim-breakpoint-sm) {
                    display: none;
                }
            }

            &-icon {
                display: none;
                height: 100%;
                width: 5rem;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                @media (min-width: $dim-breakpoint-sm) {
                    display: flex;
                }

                &-icon {
                    @include ff-icon;
                    font-size: 2.5rem;
                    color: $color-primary;
                }

                &-count {
                    position: absolute;
                    right: 0.5rem;
                    top: 2rem;
                    width: 2rem;
                    height: 2rem;
                    background-color: $color-primary;
                    color: $color-primary-c;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    font-size: 1.4rem;
                    line-height: 1em;
                    border-radius: 1rem;

                    &.show {
                        display: flex;
                    }
                }
            }
        }
    }
}
