._privacy {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;

    &__content {
        width: 100%;
        max-width: 800px;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
        padding: 2rem;
    }

    &__title {
        color: $color-primary;
        font-size: 2.4rem;
        font-weight: bold;
    }

    &__text {
        font-size: 1.6rem;

        a {
            color: $color-primary;
            overflow-wrap: anywhere;
        }
    }
}

._privacy-banner {
    width: calc(100% - 4rem);
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    max-width: 400px;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
    z-index: 100;
    display: flex;
    align-items: stretch;

    &__close {
        @include ff-icon;
        padding: 2rem;
        font-size: 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font-size: 1.4rem;
        padding: 2rem;

        a {
            color: $color-primary;
            overflow-wrap: anywhere;
        }
    }
}
