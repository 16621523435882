@import "../../../scss/variables";

._product-details {
    display: flex;
    width: 100%;
    flex: 1 0 30rem;
    justify-content: center;
    padding: 1rem;
    z-index: 1;

    &__status {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10rem 0 0 0;

        &-title {
            font-size: 2.4rem;
            text-align: center;
            color: $color-primary;
            text-transform: uppercase;
            font-weight: bold;
        }

        &-description {
            font-size: 1.6rem;
            text-align: center;
            padding: 2rem 0 0 0;
            font-weight: 500;
        }
    }

    &__content {
        width: 100%;
        max-width: 98rem;
        box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
        background-color: $color-elem-background;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__photos {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $color-grey-4;
        position: relative;

        &-no {
            height: 40rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @include ff-icon;
            font-size: 7rem;
            color: $color-grey-1;
        }

        &-photo {
            display: flex;
            justify-content: center;
            align-items: center;

            &-img {
                max-height: 60rem;
                width: 100%;
                object-fit: contain;
            }
        }

        &-buttons {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &-button {
            @include ff-icon;
            font-size: 3rem;
            margin: 2rem;
            width: 4rem;
            height: 4rem;
            border-radius: 2rem;
            background-color: rgba($color-elem-background, 0);
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 0rem 0rem $color-elem-shadow;
            color: $color-primary;
            transition: all 0.2s ease-out;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
                background-color: $color-elem-background;
            }
        }
    }

    &__basic {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        border-bottom: 1px solid $color-grey-4;

        &-name {
            font-size: 3rem;
            font-weight: bold;
            color: $color-primary;
            padding: 0.5rem 0;
        }

        &-ean {
            font-size: 3rem;
            font-weight: bold;
            color: $color-grey-1;
        }

        &-category,
        &-type {
            font-size: 2rem;
            padding: 0.5rem 0;
        }
    }

    &__definition-group {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        border-bottom: 1px solid $color-grey-4;

        &-title {
            font-size: 2.4rem;
            font-weight: bold;
            color: $color-primary;
            padding: 0 0 1rem 0;
        }
    }

    &__definition-item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
        }

        &-label {
            font-size: 1.6rem;
            color: $color-grey-2;
            font-weight: bold;
            padding: 0.5rem 0;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 30%;
            }
        }

        &-content {
            font-size: 1.6rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 1 0 30%;
            }

            p {
                padding: 0.5rem 0;
                margin: 0;
            }

            h1 {
                font-size: 2rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            h3 {
                font-size: 1.6rem;
            }
        }
    }

    &__order {
        display: flex;
        justify-content: flex-end;
        padding: 2rem 2rem 0 2rem;
    }

    &__youtube {
        display: flex;
        padding: 2rem;

        &-player {
            width: 100%;
            height: 50vw;

            @media (min-width: $dim-breakpoint-md) {
                height: 50rem;
            }
        }
    }
}

._catalogue-product__files {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: $color-background;
    padding: 1rem;

    ._catalogue-product__file {
        width: 30%;
        background-color: $color-elem-background;
        box-shadow: 0 0 0.2rem 0.1rem $color-elem-shadow;
        border-radius: 0.3rem;
        margin: 1rem 0.5rem;
        overflow: hidden;

        a {
            text-decoration: none;
            color: $color-primary;
            font-size: 1.4rem;
        }

        ._catalogue-product__file-preview {
            width: 100%;
            border-bottom: 1px solid $color-grey-4;

            img {
                width: 100%;
                max-height: 300px;
            }
        }

        ._catalogue-product__file-preview-empty {
            width: 100%;
            height: 200px;
            @include ff-icon();
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 5rem;
            color: $color-grey-3;
        }

        ._catalogue-product__file-label {
            padding: 1rem;
        }
    }
}

._product-details__binded {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-type {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid $color-grey-4;

        // &:not(:last-child) {
        //     border-bottom: 1px solid $color-grey-4;
        // }

        &-header {
            display: flex;
            justify-content: space-between;
            padding: 2rem;
            align-items: center;

            &-title {
                font-size: 1.6rem;
            }

            &-icon {
                @include ff-icon;
                font-size: 2.5rem;
                color: $color-grey-2;
            }
        }
    }

    &-category-main {
        &-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-left: 5px solid $color-grey-3;
        }

        &-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-top: 1px solid $color-grey-4;
            background-color: $color-grey-5;

            &-header {
                display: flex;
                justify-content: space-between;
                padding: 2rem;
                align-items: center;

                &-title {
                    font-size: 1.6rem;
                }

                &-icon {
                    @include ff-icon;
                    font-size: 2.5rem;
                    color: $color-grey-2;
                }
            }
        }
    }

    &-category-secondary {
        &-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-left: 5px solid $color-grey-6;
        }

        &-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-top: 1px solid $color-grey-3;
            background-color: $color-grey-4;

            &-header {
                display: flex;
                justify-content: space-between;
                padding: 2rem;
                align-items: center;

                &-title {
                    font-size: 1.6rem;
                }

                &-icon {
                    @include ff-icon;
                    font-size: 2.5rem;
                    color: $color-grey-2;
                }
            }
        }
    }

    &-product {
        &-list {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $color-grey-3;
            padding: 0.5rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: stretch;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            margin: 0.5rem;
            box-shadow: 0 0 0.3rem 0.1rem $color-elem-shadow;
            background-color: $color-elem-background;
            padding: 1rem;
            border-radius: 0.5rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 30%;
            }

            &-button {
                display: flex;
                justify-content: flex-end;
            }

            &-info-name {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-primary;
            }

            &-info-ean {
                font-size: 1.6rem;
                color: $color-grey-2;
                font-weight: bold;
            }
        }
    }
}
