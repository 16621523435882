$pathToFont_ge-healthcare: '/assets/fonts/_ge-inspira/';

@font-face {
	font-family: 'GE Inspira';
	src: url($pathToFont_ge-healthcare + 'GEInspira-Medium.eot');
	src: url($pathToFont_ge-healthcare + 'GEInspira-Medium.eot?#iefix') format('embedded-opentype'),
	url($pathToFont_ge-healthcare + 'GEInspira-Medium.woff2') format('woff2'),
	url($pathToFont_ge-healthcare + 'GEInspira-Medium.woff') format('woff'),
	url($pathToFont_ge-healthcare + 'GEInspira-Medium.ttf') format('truetype'),
	url($pathToFont_ge-healthcare + 'GEInspira-Medium.svg#GEInspira-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'GE Inspira';
	src: url($pathToFont_ge-healthcare + 'GEInspira.eot');
	src: url($pathToFont_ge-healthcare + 'GEInspira.eot?#iefix') format('embedded-opentype'),
	url($pathToFont_ge-healthcare + 'GEInspira.woff2') format('woff2'),
	url($pathToFont_ge-healthcare + 'GEInspira.woff') format('woff'),
	url($pathToFont_ge-healthcare + 'GEInspira.ttf') format('truetype'),
	url($pathToFont_ge-healthcare + 'GEInspira.svg#GEInspira') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GE Inspira';
	src: url($pathToFont_ge-healthcare + 'GEInspira-Bold.eot');
	src: url($pathToFont_ge-healthcare + 'GEInspira-Bold.eot?#iefix') format('embedded-opentype'),
	url($pathToFont_ge-healthcare + 'GEInspira-Bold.woff2') format('woff2'),
	url($pathToFont_ge-healthcare + 'GEInspira-Bold.woff') format('woff'),
	url($pathToFont_ge-healthcare + 'GEInspira-Bold.ttf') format('truetype'),
	url($pathToFont_ge-healthcare + 'GEInspira-Bold.svg#GEInspira-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

$font-family: 'GE Inspira', sans-serif;

@mixin ff-ge() {
	font-family: 'GE Inspira', sans-serif;
}
