@import "../../scss/variables";

._order-modal {
    display: flex;
    position: fixed;
    justify-content: flex-end;
    align-items: stretch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color-background, 0.8);
        z-index: 1;
    }

    &__content {
        z-index: 2;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: $color-elem-background;
        flex-direction: column;

        @media (min-width: $dim-breakpoint-sm) {
            width: 40rem;
            background-color: $color-elem-background;
            box-shadow: 0 0 1rem 0.1rem $color-elem-shadow;
        }
    }

    &__header {
        display: flex;
        height: $dim-nav-height;
        flex: 0 0 $dim-nav-height;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $color-grey-4;

        &-title {
            flex: 1 0 10rem;
            padding: 1rem 2rem;
            font-size: 1.6rem;
            text-transform: uppercase;
            font-weight: bold;
            color: $color-primary;
        }

        &-close {
            flex: 0 0 $dim-nav-height;
            @include ff-icon;
            display: flex;
            font-size: 2.4rem;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
        }
    }

    &__scroll {
        flex: 1 0 20rem;
        overflow: scroll;
        padding: 1rem;
        position: relative;
    }

    &__product {
        display: flex;
        margin: 1rem 0;
        border: 1px solid $color-grey-3;
        border-radius: 0.5rem;
        overflow: hidden;

        &-photo {
            flex: 0 0 30%;
            border-right: 1px solid $color-grey-3;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: auto;
            }

            &-icon {
                height: 8rem;
                background-color: $color-grey-3;
                display: flex;
                justify-content: center;
                align-items: center;
                @include ff-icon;
                font-size: 3rem;
                color: $color-grey-2;
                width: 100%;
            }
        }

        &-info {
            flex: 0 0 100%;
        }

        &-right {
            flex: 0 0 70%;
            display: flex;
            flex-wrap: wrap;
            padding: 0.5rem 1rem;
            justify-content: space-between;
            align-items: center;
        }

        &-name {
            font-size: 1.4rem;
            font-weight: 500;
        }

        &-ean {
            font-size: 1.4rem;
            padding: 0.5rem 0;
        }

        &-count {
            input {
                padding: 0.5rem;
                height: 3rem;
                border: 2px solid $color-primary;
                border-radius: 0.5rem;
                width: 7rem;
                text-align: center;
                font-size: 1.6rem;
            }
        }

        &-remove {
            @include ff-icon;
            font-size: 2rem;
            color: $color-grey-2;
            cursor: pointer;
        }
    }

    &__info-empty {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-primary;
    }

    &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color-elem-background, 0.9);
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: $color-primary;
        padding-top: 10rem;
    }
}

._order-modal__form {
    display: flex;
    flex-direction: column;

    &-label {
        padding: 1rem 0 0.5rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 500;
    }

    input {
        border: 2px solid $color-primary;
        padding: 0.5rem 1rem;
        font-size: 1.6rem;
        border-radius: 0.5rem;
    }

    .button {
        margin-top: 3rem;
        font-size: 1.6rem;
        padding: 1rem;
    }
}
