@mixin ff-title() {
    font-family: 'GE Inspira', sans-serif;
}
@mixin ff-text() {
    font-family: 'GE Inspira', sans-serif;
}

@mixin ff-icon() {
    font-family: 'Material Icons', sans-serif;
}
