$color-primary: #0066ae;
$color-primary-dark: #004374;
$color-primary-light: #3a729b;
$color-primary-c: #ffffff;

$color-grey-1: #aaaaaa;
$color-grey-2: #7e7e7e;
$color-grey-3: #dfdfdf;
$color-grey-4: #ececec;
$color-grey-5: rgb(250, 250, 250);
$color-grey-6: #cccccc;
$color-grey-7: #bdbdbd;


$color-background-3: rgb(250, 250, 250);
$color-background-2: rgb(248, 248, 248);
$color-background: rgb(246, 246, 246);
$color-elem-background: #ffffff;
$color-elem-shadow: rgba(102, 102, 102, 0.15);

$color-red: rgb(218, 56, 56);

$color-nav-toggle: $color-grey-1;

@mixin custom-shadow(){
    box-shadow: 0 0 1rem .1rem $color-elem-shadow;
}