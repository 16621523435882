@import "../scss/variables";

._routing {
    padding: $dim-nav-height 0 0 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &__footer {
        flex: 1 0 15rem;
        display: flex;
        padding: 2rem;
        justify-content: center;
        align-items: flex-end;
        font-size: 1.4rem;
        color: $color-grey-2;

        p {
            text-align: center;
        }

        a {
            color: $color-primary;
        }
    }
}
