@import "../scss/variables";

.search {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 110;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
        content: "";
        position: fixed;
        // background-color: rgba(255, 255, 255, 1);
        background-color: $color-background;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    &__header {
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 900px;

        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-title {
            padding: 1rem;
            font-size: 3rem;
            font-weight: bold;
            color: $color-primary;
            // text-transform: uppercase;
        }

        &-close-button {
            @include ff-icon;
            padding: 1rem;
            font-size: 3rem;
            // color: $color-primary;
            cursor: pointer;
        }

        &-field {
            width: 100%;
            padding: 1rem;
            display: flex;

            &-input-wrapper {
                flex: 1 0 10rem;
            }

            &-input {
                width: 100%;
                height: 6rem;
                font-size: 1.8rem;
                @include ff-text;
                padding: 0 1rem;
                border-radius: 0.5rem 0 0 0.5rem;
                border: 2px solid $color-primary;
            }

            &-button {
                flex: 0 0 6rem;
                @include ff-icon;
                font-size: 3rem;
                color: white;
                background-color: $color-primary;
                border: none;
                cursor: pointer;
                border-radius: 0 0.5rem 0.5rem 0;

                &:hover {
                    background-color: $color-primary-dark;
                }
            }
        }
    }

    &__results {
        width: 100%;
        z-index: 2;
        max-width: 900px;
        position: relative;
    }
}
