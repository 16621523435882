@import "../../scss/variables";

._start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0rem;

    @media (min-width: $dim-breakpoint-sm) {
        padding: 1rem;
    }

    &__center {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1000px;
        align-items: flex-start;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media (min-width: $dim-breakpoint-lg) {
            max-width: $dim-breakpoint-lg;
        }
    }

    &__slider {
        width: 100%;
    }
}

._start-slider {
    width: 100%;
    padding: 1rem;

    &__container {
        display: flex;
        width: 100%;
        position: relative;
        // min-height: 10vh;
        border-radius: 0.5rem;
        background-color: $color-elem-background;
        @include custom-shadow;
        overflow: hidden;
        align-items: center;
        padding-bottom: 4rem;

        @media (min-width: $dim-breakpoint-sm) {
            padding-bottom: 0;
            // min-height: 30vh;
        }
    }

    &__arrow {
        &-container {
            cursor: pointer;
            position: absolute;
            width: 6rem;
            padding: 1rem;
            background-color: transparent;
            display: flex;
            height: 100%;
            align-items: center;
            bottom: 0;
            height: 4rem;
            z-index: 3;

            @media (min-width: $dim-breakpoint-sm) {
                top: 0;
                height: 100%;
            }

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }

        &-icon {
            @include ff-icon;
            font-size: 4rem;
            width: 4rem;
            height: 4rem;
            border-radius: 2rem;
            background-color: white;
            color: $color-primary;
            text-align: center;

            @media (min-width: $dim-breakpoint-sm) {
                @include custom-shadow;
            }
        }
    }
    &__dots {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    &__dot {
        width: 2rem;
        height: 2rem;
        background-color: $color-elem-background;
        @include custom-shadow;
        border-radius: 1rem;
        margin: 0 0.5rem;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
        }
    }

    &__slides {
        width: 100%;
        display: flex;
        position: absolute;
    }

    &__shape {
        width: 100%;
        display: flex;

        &-img {
            width: 100%;
        }
    }

    &__slide {
        width: 100%;
        display: flex;

        a {
            display: flex;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        &-img {
            width: 100%;
        }
    }
}

._start-info {
    width: 100%;
    padding: 1rem;

    @media (min-width: $dim-breakpoint-sm) {
        width: 70%;
    }

    &__container {
        display: flex;
        width: 100%;
        position: relative;
        border-radius: 0.5rem;
        background-color: $color-elem-background;
        @include custom-shadow;
        overflow: hidden;
        align-items: center;
        padding: 1rem;
        flex-direction: column;
    }

    &__text {
        font-size: 1.6rem;
    }

    &__show-instruction {
        margin: 1rem 0;
        text-align: center;
        font-size: 1.6rem;
        color: $color-primary;
        display: flex;
        align-items: center;
        cursor: pointer;

        &-icon {
            @include ff-icon;
            font-size: 2rem;
        }
    }

    &__instruction {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;

        &-title {
            font-weight: bold;
            padding: 1rem 0;
        }

        ul {
            padding: 0rem 2rem;
        }

        &-text {
            &-icon {
                @include ff-icon;
                color: $color-primary;
            }
        }
    }

    &__browse {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
        }

        a.button {
            font-size: 2rem;
            padding: 1rem;
            width: 100%;
            text-align: center;
            margin: 1rem 0 0;

            @media (min-width: $dim-breakpoint-sm) {
                &:first-child {
                    margin-right: 0.5rem;
                }
                &:last-child {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

._start-about {
    width: 100%;
    padding: 1rem;

    @media (min-width: $dim-breakpoint-sm) {
        width: 30%;
    }

    &__container {
        display: flex;
        width: 100%;
        position: relative;
        border-radius: 0.5rem;
        background-color: $color-elem-background;
        @include custom-shadow;
        overflow: hidden;
        align-items: center;
        padding: 1rem;
        flex-direction: column;

        &.container-2 {
            margin-top: 2rem;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        width: 100%;
        padding-bottom: 2rem;

        &-logo {
            flex: 0 0 4rem;

            &-img {
                width: 100%;
            }
        }

        &-text {
            flex: 1 0 5rem;
            text-align: center;
            font-size: 2rem;
            padding-right: 4rem;
            font-weight: bold;

            &.no-padding {
                padding: 0rem;
            }
        }
    }

    &__text {
        padding: 0.5rem 0;
        font-size: 1.6rem;
        width: 100%;
    }

    &__button {
        margin-top: 1rem;
        text-align: center;
    }
}
